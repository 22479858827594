<template>
	<div class="doctor-list">

		<!-- <el-card class="filter-container" shadow="never" v-if="false">
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="value" size="small">
					<el-form-item>
						<el-select v-model="form.region" placeholder="标题">
							<el-option label="区域一" value="shanghai"></el-option>
							<el-option label="区域二" value="beijing"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item>
						<el-select v-model="form.region" placeholder="属性">
							<el-option label="区域一" value="shanghai"></el-option>
							<el-option label="区域二" value="beijing"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="form.region" placeholder="状态">
							<el-option label="区域一" value="shanghai"></el-option>
							<el-option label="区域二" value="beijing"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-date-picker v-model="value1" type="datetimerange" range-separator="至" start-placeholder="开始日期"
						 end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-form>


				<el-form :inline="true" :model="value" size="small">
					<el-form-item>
						<el-input style="width:300px" v-model="value" placeholder="关键字"></el-input>
					</el-form-item>

					<el-form-item label=" ">
						<el-button type="primary" size="small">
							查询结果
						</el-button>
						<el-button style="margin-right: 15px" size="small">
							重置
						</el-button>
					</el-form-item>
				</el-form>

			</div>
		</el-card> -->
		
		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>文章分类列表</span></el-col>
				<el-col style="display: flex;justify-content: flex-end;">
					<el-button  type="primary" size="small" @click="getlist()">
						查询
					</el-button>
					
					<el-button type="success" size="small" @click="goEdit('add')">
						新增
					</el-button>
				</el-col>
			</el-row>
		</el-card>


		<el-card style="margin-top: 20px;" shadow="never" v-if="false">
			<el-row style="display: flex;justify-content: flex-end;">
				<el-col><i class="el-icon-tickets"></i><span>数据列表</span></el-col>
				<el-col >
					
					<el-button class="btn-add" size="mini">
						删除
					</el-button>
					
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;" shadow="never">
			<el-table :data="menuData" style="width: 100%;" >
				<!-- <el-table-column type="selection" width="55" align="center">
				</el-table-column> -->
				<el-table-column label="排序" align="center"  prop="listorder"  :sortable="true">
					<template slot-scope="scope">
						<el-input style="width: 80px;" v-model="scope.row.listorder" @blur="blur(scope)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="id" align="center" >
					<template slot-scope="scope">
						<span>{{ scope.row.id }}</span>
					</template>
				</el-table-column>
			<!-- 	<el-table-column label="类型" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column> -->
				
				
				<el-table-column label="菜单标题" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.menuName }}</span>
					</template>
				</el-table-column>

				<!-- <el-table-column label="模型" align="center">
					<template slot-scope="scope">
					<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column> -->

			
			
				<el-table-column label="是否启用" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.menuOpen" @change="switchbtn(scope.row.id)">
						</el-switch>
					</template>
				</el-table-column>

			

				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<!-- <el-button size="mini"  type="text">还原</el-button> -->
							
							<el-button size="mini" type="text" @click="goEdit('edit',scope.row.id)">修改</el-button>
							<el-button size="mini" type="text"  @click="goEdit('sub',scope.row.id)">添加子类</el-button>
                            <el-button size="mini" type="text"  @click="del(scope)">删除</el-button>
							<!-- <el-button size="mini" type="text">发布内容</el-button> -->
						</p>
					</template>
				</el-table-column>
			</el-table>
		</el-card>



		<el-pagination v-if="pageshow" :hide-on-single-page="hideonsinglepage" background :total="5" layout="prev, pager, next">
		</el-pagination>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					name: '',
				},
				menuData: [],
				pageshow:false
			}
		},
		methods: {
			sortByOrder(d1,d2){
				return d1.listorder - d2.listorder;
			},
			//跳转添加页
			goEdit(action,id){
				this.$router.push({name: 'newsmenuadd',query:{id: id,ac:action}});
			},
			// 获取列表
			getlist() {
				const that = this;
				that.api('/admin/menu/list').then(res => {
					for(var i =0; i<res.data.length;i++){
						if(res.data[i].menuOpen=='1'){
							res.data[i].menuOpen=true
						}else{
							res.data[i].menuOpen=false
						}
					}					
					that.menuData = res.data;
					// that.pageNo = res.data.pageNo;
					// this.pageshow = false; //让分页隐藏
					// this.$nextTick(() => { //重新渲染分页
					// 	this.pageshow = true;
					// });
				})
			},
			// 按钮开关
			switchbtn(id){
				const that=this;
				that.api('/admin/menu/state',{id:id}).then(res=>{
					if(res.code==200){
						that.getlist();
					}
				})
			},
			// 单条排序
			blur(e) {
				const that = this;
				var data = [{
					id: e.row.id,
					listorder: Number(e.row.listorder)
				}]
			
				that.api('/admin/menu/order', data).then(res => {
					if (res.code == 200) {
						that.getlist();
					}
				})
			},
			// 删除
			del(data) {
				const that = this;
                that.$confirm('删除该条栏目信息也会将该栏目下的文章一并删除，您确认要删除么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api('/admin/menu/del', {
                        id:data.row.id
                    }).then(res => {
                        if (res.code == 200) {
                            that.$message.success("删除成功!");
                            that.menuData.splice(data.$index,1)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

			},
		},
		created(){
			const that = this;
			that.getlist();
		}
	}
</script>

<style lang="less" scoped>
	.delBtn{
		margin-right:10px;
	}

</style>
